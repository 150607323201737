export const DISPLAY_VERSION_LABEL = 'Display Version';
export const DISPLAY_VERSION_PLACE_HOLDER_ = 'Select a Display';
export enum AIRCRAFT_MAKE_TYPE {
  BOEING = 'Boeing',
  AIRBUS = 'Airbus',
}

export enum DISPLAY_VERSION {
  LEGACY_DISPLAY = 'A708 Legacy Display',
}
// Applicable software features to apply condtions in display settings
export enum SOFTWARE_FEATURES_ITEMS {
  LIGHTNING_ICON_TYPE = 'Lightning Icon', // based on purchased software features and aircraft type
  HAIL_ICON_TYPE = 'Hail Icon',
  CELL_TRACKING_TYPE = 'Cell Tracking and Trending',
}

export enum DISPLAY_SETTINGS_FIELDS {
  A708 = 'A708 Legacy Display',
  COASTING_PERIOD = 'No UTC Maximum coasting period',
  LEFT_POSITION = 'Text Overlay Left Position',
  BOTTOM_POSITION = 'Text Overlay Bottom Position',
  MODE_COLOR = 'Text Overlay Mode Color',
  MANUAL_ELAVATION = 'Manual Elevation Rapid Redraw',
  ANGLE = 'Elevation Compensation Angle (Radians)',
  ICON_SYMBOL_COLOR = 'Icon Symbol Color',
  ICON_BORDER_COLOR = 'Icon Border Color',
  RANGE_LIMIT = 'NRCS Uncertainty Boost Range Limit',
  BOOST_FACTOR = 'NRCS Uncertainty Boost Factor',
}
