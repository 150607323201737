export const SELECT_LABEL = {
  make: 'Make',
  model: 'Model',
  stc: 'Cert Number',
  installMode: 'Install Mode',
};

export const SELECT_PLACEHOLDER = {
  make: 'Select Make',
  model: 'Select Model',
  stc: 'Select Cert Number',
  installMode: 'Select Mode',
};

export const BUTTON_CONTENT = {
  back: 'Back',
  next: 'NEXT',
};

export const HEADING_TEXT = {
  confirmMsg: 'Confirm your Aircraft, Cert and Install Mode',
};

export const WINDSHEAR_TEXT = { predictiveWindshear: 'Predictive WindShear' };
