import * as Yup from 'yup';

export const ConfirmSTCValidationSchema = Yup.object().shape({
  model: Yup.string().when('make', {
    is: make => make === undefined,
    then: Yup.string().required('Field is required'),
  }),
  stc: Yup.string().when('model', {
    is: model => model === undefined,
    then: Yup.string().required('Field is required'),
  }),
  installMode: Yup.string()
    .required('Field is required')
    .when('stc', {
      is: stc => stc === undefined,
      then: Yup.string().required('Field is required'),
    }),
});
