import React, { Component, ErrorInfo } from 'react';
import { Link } from 'react-router-dom';
import { Notification } from '@scuf/common';

interface IErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends Component<{}, IErrorBoundaryState> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.error('ErrorBoundary caught an error : ', error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Notification hasIcon={true} title="There was an error rendering the section" severity="critical">
          <Link to="/">Click here</Link> to go back to the home page.
        </Notification>
      );
    }

    return this.props.children;
  }
}
