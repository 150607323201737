import React, { FC } from 'react';
import { Checkbox } from '@scuf/common';

interface IAircraftsTableInputProps {
  name: string;
  checked: boolean;
  disabled: boolean;
  onChange: (value: boolean, name: string) => void;
}

export const AircraftsTableInput: FC<IAircraftsTableInputProps> = ({ name, checked, disabled, onChange }) => {
  return (
    <>
      <Checkbox
        name={name}
        checked={checked}
        onChange={value => {
          onChange(value, name);
        }}
        disabled={disabled}
      />
    </>
  );
};
