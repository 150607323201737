import { IEnvVars } from 'shared/utils';

const PROD_ENV_VARS: IEnvVars = {
  CLIENT_ID: 'Client_617',
  CLIENT_SECRET: '$0my5*cdrnt1gu9$',
  API_ENDPOINT: 'https://rdr-web-exp-api.aerospace1.honeywell.com/rdr4k',
  ASDS_ENDPOINT: 'https://ads.honeywell.com/ads/broadcast/message',
  ASDS_ERROR_ENDPOINT:
    'https://aerospace.honeywell.com/en/secure/learn/products/weather-radar/rdr-7000/software-configuration',
  ASDS_GET_HELP: 'https://aerospace.honeywell.com/en/pages/rdr-7k-support',
  ASDS_ADG_LITE: 'https://qasds.honeywell.com/servicerequest/signsweb/pages/manageConfiguration/view.do?crlFilePath=.',
  ORDER_STATUS: ' https://aerospace.honeywell.com/us/en/redirects/sso/order-status',
};

export default PROD_ENV_VARS;
