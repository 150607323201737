export const forceDownload = (fileData: string, fileNameHeader: string) => {
  const fileName = fileNameHeader.split(';')[1].split('=')[1];
  const downloadUrl = window.URL.createObjectURL(new Blob([fileData]));
  const link = document.createElement('a');

  link.href = downloadUrl;
  link.setAttribute('download', fileName); // any other extension

  document.body.appendChild(link);

  link.click();
  link.remove();
};
