import React, { FC } from 'react';
import { useField, useFormikContext } from 'formik';
import { Select } from '@scuf/common';
import { IOption } from '@scuf/common/dist/components/Select/ISelectProps';
import { IConfirmStcFormFields } from 'models/confirmSTC';

interface ConfirmSTCSelectProps {
  name: string;
  options: Array<IOption>;
  label: string;
  placeholder: string;
  initialDisable: boolean;
  changeOption?: (changedValue: string) => void;
}
export const ConfirmSTCSelect: FC<ConfirmSTCSelectProps> = ({
  name,
  options,
  label,
  placeholder,
  initialDisable,
  changeOption,
}) => {
  // this will return field props for an <input />
  const [field, meta, helpers] = useField(name);
  const { values: formValues } = useFormikContext<IConfirmStcFormFields>();

  const { value, error } = meta;
  const { setValue, setTouched } = helpers;
  return (
    <>
      <Select
        className="select-box"
        indicator="required"
        label={label}
        placeholder={placeholder}
        options={options}
        defaultValue={value}
        value={value}
        disabled={
          (error !== undefined && (formValues.make === '' || formValues.model === '' || formValues.stc === '')) ||
          initialDisable
        }
        onFocus={() => setTouched(true)}
        onChange={(changedValue: string) => {
          if (changeOption !== undefined) {
            changeOption(changedValue);
          }
          setValue(changedValue);
        }}
      />
    </>
  );
};
