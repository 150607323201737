export const COLUMN_HEADER = {
  purchaseDate: 'PURCHASE DATE',
  purchaseOrder: 'PO',
  salesOrder: 'SALES ORDER #',
};

export const BUTTON_CONTENT = {
  cancel: 'Cancel',
  next: 'NEXT',
};

export const HEADING_TEXT = {
  selectOrder: 'Select an order to configure:',
};
