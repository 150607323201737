export const LABELS = {
  salesOrder: 'Sales Order:',
  purchaseOrder: 'PO:',
  purchaseDate: 'Purchase Date:',
  upgrades: 'Upgrades Purchased:',
};

export const SelectSoftwareHeading = {
  upgradeHeading: 'Your selected Upgrade',
  aircraftHeading: 'Select The Radar/Aircraft(s) To Upgrade:',
  featureHeading: 'Select the features you want in your configuration:',
};

export const COLUMN_HEADER = {
  configModule: 'Radar Config Module SN#',
  tailNumber: 'Tail #',
  aircraft: 'Aircraft SN#',
};

export const COLUMN_HEADER_2 = {
  softwareDesc: 'Software Description',
  salesOrderQty: 'Sales Order Quantity',
  remaining: 'Remaining',
};

export const ERROR_MSG = {
  aircraftQtyError: 'Aircraft qty exceeds software qty',
  alreadyAppliedError: 'The software feature is already applied to the file',
  compatiblityError: 'The software feature is not compatible with the selected make, model, cert and install mode',
  appliedDuringUpgrade: 'Applied during original upgrade transaction',
};
