import React, { FC } from 'react';
import { IDropdownOptionsProps } from './IDropdownOptionsProps';
import { LABEL_TEXT } from '../constants';
import './DropdownOptions.scss';

export const DropdownOptions: FC<IDropdownOptionsProps> = ({ options, onOptionItemSelect, showLoader }) => {
  if (showLoader) {
    return (
      <div
        role="option"
        aria-checked="false"
        aria-selected="true"
        className="selected item"
        style={{ pointerEvents: 'all' }}
      >
        {LABEL_TEXT.loadingOptions}
      </div>
    );
  }

  return options && options.length > 0 ? (
    <>
      {options.map(option => (
        <div
          role="option"
          aria-checked="false"
          aria-selected="true"
          className="selected item"
          style={{ pointerEvents: 'all', background: '#fff' }}
          key={option.soldTo}
          onClick={() => onOptionItemSelect(option)}
        >
          <h5>{option.customerName}</h5>
          <h5>
            {LABEL_TEXT.customerCode} {option.soldTo}
          </h5>
          <h5>{option.address}</h5>
        </div>
      ))}
    </>
  ) : (
    <div
      role="option"
      aria-checked="false"
      aria-selected="true"
      className="selected item"
      style={{ pointerEvents: 'all' }}
    >
      <strong>{LABEL_TEXT.noData}</strong>
    </div>
  );
};
