export const FOOTER_TEXT = {
  copyright: 'Copyright © 2020 Honeywell International Inc.',
  terms: 'Terms & Conditions',
  privacy: 'Privacy Statement',
  donotsell: 'Your Privacy Choices',
  cookies: 'Cookies',
  global: 'Global Unsubscribe',
};

export const footerInfo = [
  { FOOTER_TEXT: FOOTER_TEXT.copyright, CLASSNAME: 'inactiveLink' },
  { FOOTER_TEXT: FOOTER_TEXT.terms, URL: 'https://www.honeywell.com/en-us/terms-and-conditions', TARGET: '_blank' },
  { FOOTER_TEXT: FOOTER_TEXT.privacy, URL: 'https://www.honeywell.com/en-us/privacy-statement', TARGET: '_blank' },
  {
    FOOTER_TEXT: FOOTER_TEXT.donotsell,
    URL: 'https://honeywellhub.secure.force.com/PrivacyInformationRequestForm?lang=en',
    TARGET: '_blank',
  },
  {
    FOOTER_TEXT: FOOTER_TEXT.cookies,
    URL: 'https://aerospace.honeywell.com/en/redirects/external/cookies',
    TARGET: '_blank',
  },
  {
    FOOTER_TEXT: FOOTER_TEXT.global,
    URL: 'https://aerospace.honeywell.com/en/redirects/external/global-unsubscribe',
    TARGET: '_blank',
  },
];
