export const TITLE_TEXT = {
  selectOrder: 'Select Order',
  confirmSTC: 'Confirm Cert',
  selectSoftware: 'Select Software',
  configure: 'Configure',
  save: 'Save',
  download: 'Download',
};

export const stepperItemData = [
  {
    className: 'stepper-item',
    title: TITLE_TEXT.selectOrder,
    active: 1,
  },
  {
    className: 'stepper-item',
    title: TITLE_TEXT.confirmSTC,
    active: 2,
  },
  {
    className: 'stepper-item',
    title: TITLE_TEXT.selectSoftware,
    active: 3,
  },
  {
    className: 'stepper-item',
    title: TITLE_TEXT.configure,
    active: 4,
  },
  {
    className: 'stepper-item',
    title: TITLE_TEXT.save,
    active: 5,
  },
  {
    className: 'stepper-item',
    title: TITLE_TEXT.download,
    active: 6,
  },
];
