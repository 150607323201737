export const LABEL_TEXT = {
  welcome: 'Welcome Aboard,',
  settings: 'Manage My Account',
  lock: 'Manage My Password',
  exit: 'Logout',
  asdsAppName: 'Software & Data Services (ASDS)',
  homeAppName: 'MyAerospace Home',
  title: 'Software & Data Services',
};

export const HEADER_URL = {
  aerospaceHome: 'https://aerospace.honeywell.com/en/myaerospace-home',
  myAccount: 'https://aerospace.honeywell.com/en/account',
  myPassword: 'https://aerospace.honeywell.com/en/account',
};
