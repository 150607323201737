import { IEnvVars } from 'shared/utils';

const QA_ENV_VARS: IEnvVars = {
  CLIENT_ID: 'Client_595',
  CLIENT_SECRET: 'Asds4000#qa',
  // API_ENDPOINT: 'https://webapp-qezvydmi-cqkbpqgt-rxtafeqg.apps.dev.aro.forgeapp.honeywell.com/rdr4k'
  API_ENDPOINT: 'https://rdr-web-exp-api.qaerospace1.honeywell.com/rdr4k',
  ASDS_ENDPOINT: 'https://qads.honeywell.com/ads/broadcast/message',
  ASDS_ERROR_ENDPOINT:
    'https://stage-aerospace.honeywell.com/en/secure/learn/products/weather-radar/rdr-7000/software-configuration',
  ASDS_GET_HELP: 'https://aerospace.honeywell.com/en/pages/rdr-7k-support',
  ASDS_ADG_LITE: 'https://qasds.honeywell.com/servicerequest/signsweb/pages/manageConfiguration/view.do?crlFilePath=.',
  ORDER_STATUS: ' https://aerospace.honeywell.com/us/en/redirects/sso/order-status',
};

export default QA_ENV_VARS;
