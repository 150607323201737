import React, { FC } from 'react';
import { Input } from '@scuf/common';
import { IAircraftDetailsInputProps } from './IAircraftDetailsInputProps';

export const AircraftDetailsInput: FC<IAircraftDetailsInputProps> = ({
  name,
  error,
  value,
  disabled,
  onChange,
  onBlur,
}) => (
  <Input name={name} fluid={true} value={value} onChange={onChange} onBlur={onBlur} error={error} disabled={disabled} />
);
