export const COLUMN_HEADER = {
  salesOrder: 'SALES ORDER',
  purchaseOrder: 'PO',
  fileName: 'FILE NAME',
  createdDate: 'DATE CREATED',
  platformCompleted: 'PLATFORM',
  quantity: 'QTY',
  tailNoDetails: 'TAIL NUMBER',
  customer: 'CUSTOMER',
};

export const HEADER_BAR = {
  new: 'New',
  upload: 'Upload',
  download: 'Download',
  edit: 'Edit',
  clone: 'Clone Settings',
  upgrade: 'Upgrade',
};

export const PLACEHOLDER_CONTENT = {
  noAccountSelected: 'Select Account above to view your file history',
  beginConfiguration: 'Click New above to begin configuring your software',
  add: 'ADD +',
  searchFor: 'Search for',
};
