export const DISPLAY_VERSION_LABEL = 'Display Version';

export enum CONNECTED_RADAR_VERSION {
  V1 = 'V1',
  V2 = 'V2',
}

export enum CONNECTED_RADAR_SETTINGS_V1 {
  FILE_SIZE = 'Maximum File Size',
  PASSKEY_TIMEOUT = 'Passkey Timeout',
  TRANSMIT_DATA = 'Transmit Data',
  ARINC619_MESSAGE_INTERVAL = 'Message Interval',
  LABEL_RATE_1 = 'ARINC429 Label Rate 1',
  LABEL_RATE_2 = 'ARINC429 Label Rate 2',
}

export enum CONNECTED_RADAR_SETTINGS_V2 {
  FILE_SIZE = 'Maximum File Size',
  PASSKEY_TIMEOUT = 'Passkey Timeout',
  WEATHER_DATA = 'Weather Data Transmit',
  MAINTANANCE_DATA = 'Maintenance Data Transmit',
  TRANSMIT_DATA = 'Transmit Data',
  ARINC429_LOW_SPEED = 'V2 ARINC429 Speed',
  HISTOGRAM_ENABLE = 'Histogram',
  ARM_LENGTH = 'Arm Length',
  COEFFICIENT_0 = 'coefficient 0',
  COEFFICIENT_1 = 'coefficient 1',
  LOWER_FREQUENCY = 'Lower Frequency',
  UPPER_FREQUENCY = 'Upper Frequency',
  SCALE_FACTOR = 'Scale Factor',
  ARINC619_MESSAGE_INTERVAL = 'Message Interval',
  LABEL_RATE_1 = 'ARINC429 Label Rate 1',
  LABEL_RATE_2 = 'ARINC429 Label Rate 2',
}
