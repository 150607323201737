export interface IAppConfig {
  readonly authorization_endpoint: string;
  readonly token_endpoint: string;
  readonly user_info_endpoint: string;
  readonly end_session_endpoint: string;
  readonly redirect_uri: string;
  readonly client_id: string;
  readonly client_secret: string;
  readonly response_type: string;
  readonly scope: string;
  readonly api_endpoint: string;
  readonly asds_endpoint: string;
  readonly asds_err_endpoint: string;
  readonly asds_get_help_endpoint: string;
  readonly asds_adg_lite_config: string;
  readonly order_status_endpoint: string;
}

export interface IEnvVars {
  CLIENT_ID: string;
  CLIENT_SECRET: string;
  API_ENDPOINT: string;
  ASDS_ENDPOINT: string;
  ASDS_ERROR_ENDPOINT: string;
  ASDS_GET_HELP: string;
  ASDS_ADG_LITE: string;
  ORDER_STATUS: string;
}

// Below is 7K Kong DEV URL. 4K Kong URL is not available yet.
// Hence commenting it out and accessing 4K DEV container URL until then.
export const DEV_HOSTNAME = 'rdr4k-webapp-dev.qaerospace1.honeywell.com';
//export const DEV_HOSTNAME = 'webapp-4000-tzkbuvcb-hgcpchiz-rxtafeqg.apps.dev.aro.forgeapp.honeywell.com';

// Below is 7K Kong QA URL. 4K Kong URL is not available yet.
// Hence commenting it out and accessing 4K QA container URL until then.
export const QA_HOSTNAME = 'rdr4k-webapp.qaerospace1.honeywell.com';
//export const QA_HOSTNAME = 'webapp-4000-tzkbuvcb-cqkbpqgt-rxtafeqg.apps.dev.aro.forgeapp.honeywell.com';

// The below one is 7K UI application Kong URL.
// Need to change it when 4K PROD Kong URL is available.
export const PROD_HOSTNAME = 'rdr4k-webapp.aerospace1.honeywell.com';

export const getEnvVariables = (hostName: string = window.location.hostname) => {
  let envEnvVars: IEnvVars;
  let issuerBaseURL: string;

  switch (hostName) {
    case DEV_HOSTNAME:
      envEnvVars = require('./env-dev').default;
      issuerBaseURL = 'https://qauthn.honeywell.com';
      break;
    case QA_HOSTNAME:
      envEnvVars = require('./env-qa').default;
      issuerBaseURL = 'https://qauthn.honeywell.com';
      break;
    case PROD_HOSTNAME:
      envEnvVars = require('./env-prod').default;
      issuerBaseURL = 'https://authn.honeywell.com';
      break;
    default:
      envEnvVars = require('./env-dev').default;
      // envEnvVars = require('./env-qa').default;
      issuerBaseURL = 'https://qauthn.honeywell.com';
  }

  return { envVars: envEnvVars, issuerBaseURL };
};

const { envVars, issuerBaseURL } = getEnvVariables();

export const appConfig: IAppConfig = {
  authorization_endpoint: `${issuerBaseURL}/as/authorization.oauth2`,
  token_endpoint: `${issuerBaseURL}/as/token.oauth2`,
  user_info_endpoint: `${issuerBaseURL}/idp/userinfo.openid`,
  end_session_endpoint: `${issuerBaseURL}/idp/startSLO.ping`,
  redirect_uri: `${window.location.origin}/authorize`,
  client_id: envVars.CLIENT_ID,
  client_secret: envVars.CLIENT_SECRET,
  response_type: 'code',
  scope: 'openid profile email',
  api_endpoint: envVars.API_ENDPOINT,
  asds_endpoint: envVars.ASDS_ENDPOINT,
  asds_err_endpoint: envVars.ASDS_ERROR_ENDPOINT,
  asds_get_help_endpoint: envVars.ASDS_GET_HELP,
  asds_adg_lite_config: envVars.ASDS_ADG_LITE,
  order_status_endpoint: envVars.ORDER_STATUS,
};
