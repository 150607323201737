import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ICellData } from '@scuf/datatable/dist/components/DataTable/IDataTableInterfaces';
import {
  fetchSelectedFileOriginalConfigData,
  resetConfigureFlowState,
  setConfigureScreenParams,
} from 'stores/ConfigureWorkFlowStore';
import { setConfigureCurrentStep } from 'stores/UIStore';
import { EnumConfigureFlow, ConfigStatusType } from 'models/configuration';
import { PLACEHOLDER_CONTENT } from '../constants';

interface ITailNumberRendererProps {
  cellData: ICellData;
}

export const TailNumberRenderer: FC<ITailNumberRendererProps> = ({ cellData }) => {
  const { rowData } = cellData;
  const dispatch = useDispatch();
  const history = useHistory();

  const onClickHandler = () => {
    const { configId, salesOrder, soldTo, accountName, addressLine } = rowData;

    dispatch(setConfigureCurrentStep(4));
    dispatch(resetConfigureFlowState(EnumConfigureFlow.Add));
    dispatch(setConfigureScreenParams({ configId, soldTo, salesOrder, accountName, addressLine }));
    dispatch(fetchSelectedFileOriginalConfigData(soldTo, configId, salesOrder, EnumConfigureFlow.Add));
    history.push('/add');
  };

  const filteredTailNoDetails = rowData.tailNoDetails.filter(
    (tail: { status: string }) => tail.status !== ConfigStatusType.Draft
  );
  let content = filteredTailNoDetails.map(({ tailNo, id }: { tailNo: string; id: number }) => (
    <div key={id}>{tailNo}</div>
  ));
  const quantity = parseInt(rowData.quantity, 10);

  if (
    quantity > rowData.tailNoDetails.length &&
    !rowData.salesOrder.includes('C-') &&
    !rowData.salesOrder.includes(',')
  ) {
    return (
      <>
        {content}
        <div className="add table-icon" onClick={onClickHandler}>
          {PLACEHOLDER_CONTENT.add}
        </div>
      </>
    );
  }

  return content;
};
