export const BUTTON_CONTENT = {
  expandAll: 'EXPAND ALL',
  collapseAll: 'COLLAPSE ALL',
};

export enum ACCORDION_SECTIONS {
  AircraftDetails = 'aircraftDetails',
  SoftwareFeatures = 'softwareFeatures',
  DisplaySettings = 'displaySettings',
  ConnectedRadarSettings = 'connectedRadarSettings',
  Both = 'both',
}

export const ACCORDION_TITLES = {
  [ACCORDION_SECTIONS.AircraftDetails]: 'Aircraft Details',
  [ACCORDION_SECTIONS.SoftwareFeatures]: 'Software Features',
  [ACCORDION_SECTIONS.DisplaySettings]: 'Display Settings',
  [ACCORDION_SECTIONS.ConnectedRadarSettings]: 'Connected Radar Settings',
};
