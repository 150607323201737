import { IAircraftFields, IDisplaySettingFields } from 'models/configuration';
import { DISPLAY_SETTINGS_FIELDS } from '../DisplaySettings/constants';
import { CONNECTED_RADAR_SETTINGS_V1, CONNECTED_RADAR_SETTINGS_V2 } from '../ConnectedRadar/constants';
import * as Yup from 'yup';

export const validationSchema = Yup.object()
  .shape({
    aircraftDetails: Yup.array()
      .of(
        Yup.object()
          .shape(
            {
              configModuleSerialNo: Yup.string()
                .when('tailNo', {
                  is: tailNo => tailNo && tailNo.trim().length > 0,
                  then: Yup.string().required('Required'),
                })
                .when('acSerialNo', {
                  is: acSerialNo => acSerialNo && acSerialNo.trim().length > 0,
                  then: Yup.string().required('Required'),
                })
                .max(8, 'Expected maximum of 8 characters')
                .matches(/^[0-9a-zA-Z]+$/, {
                  message: 'Expected alphanumeric characters only',
                  excludeEmptyString: true,
                })
                .matches(/(DA)/, {
                  message: 'Expected starting with DA',
                  excludeEmptyString: true,
                }),
              tailNo: Yup.string()
                .when('configModuleSerialNo', {
                  is: configModuleSerialNo => configModuleSerialNo && configModuleSerialNo.trim().length > 0,
                  then: Yup.string().required('Required'),
                })
                .when('acSerialNo', {
                  is: acSerialNo => acSerialNo && acSerialNo.trim().length > 0,
                  then: Yup.string().required('Required'),
                })
                .min(1, 'Expected minimum of 1 character')
                .max(30, 'Expected maximum of 30 characters')
                .matches(/^[a-zA-Z0-9_-]+$/, {
                  message: 'Expected alphanumeric characters, "-" and "_" only',
                  excludeEmptyString: true,
                }),
              acSerialNo: Yup.string()
                .when('configModuleSerialNo', {
                  is: configModuleSerialNo => configModuleSerialNo && configModuleSerialNo.trim().length > 0,
                  then: Yup.string().required('Required'),
                })
                .when('tailNo', {
                  is: tailNo => tailNo && tailNo.trim().length > 0,
                  then: Yup.string().required('Required'),
                })
                .min(1, 'Expected minimum of 1 character')
                .max(30, 'Expected maximum of 30 characters')
                .matches(/^[a-zA-Z0-9_-]+$/, {
                  message: 'Expected alphanumeric characters, "-" and "_" only',
                  excludeEmptyString: true,
                }),
            },
            [
              ['tailNo', 'acSerialNo'],
              ['configModuleSerialNo', 'acSerialNo'],
              ['configModuleSerialNo', 'tailNo'],
            ]
          )
          .required('Required')
      )
      .test(
        'oneItemNeeded',
        'Atleast one Aircraft details required',
        (fieldValue: IAircraftFields[] | null | undefined) => {
          let isValid = false;
          fieldValue?.forEach(airCraft => {
            if (
              airCraft.acSerialNo !== undefined ||
              airCraft.configModuleSerialNo !== undefined ||
              airCraft.tailNo !== undefined
            ) {
              isValid = true;
            }
          });
          return isValid;
        }
      )
      .required('Required'),

    displaySettings: Yup.array()
      .of(
        Yup.object()
          .shape({
            value: Yup.string()
              .nullable(true)
              .when('name', {
                is: name => name && name.toLowerCase().trim() === DISPLAY_SETTINGS_FIELDS.ANGLE.toLowerCase().trim(),
                then: Yup.string()
                  .max(900719925474099, 'Must be a decimal value')
                  .matches(/\.\d+$/, {
                    message: 'Must be a decimal value',
                  })
                  .required('Required'),
              })
              .required('Required'),
            inputValue: Yup.number()
              .nullable(true)
              .when('name', {
                is: name =>
                  name && name.toLowerCase().trim() === DISPLAY_SETTINGS_FIELDS.RANGE_LIMIT.toLowerCase().trim(),
                then: Yup.number()
                  .min(0.0, 'Range must be between 0.0 - 320.0')
                  .max(320, 'Range between 0.0 - 320.0')
                  .required('Required'),
              })
              .when('name', {
                is: name =>
                  name && name.toLowerCase().trim() === DISPLAY_SETTINGS_FIELDS.BOOST_FACTOR.toLowerCase().trim(),
                then: Yup.number()
                  .min(0.1, 'Range  must be between 0.1 - 1000.0')
                  .max(1000.0, 'Range  must be between 0.1 - 1000.0')
                  .required('Required'),
              })
              .when('name', {
                is: name =>
                  name && name.toLowerCase().trim() === DISPLAY_SETTINGS_FIELDS.LEFT_POSITION.toLowerCase().trim(),
                then: Yup.number()
                  .test('maxDigitsAfterDecimal', 'Must be only integer', number => Number.isInteger(number))
                  .max(900719925474099, 'Must be only max integer number')
                  .required('Required'),
              })
              .when('name', {
                is: name =>
                  name && name.toLowerCase().trim() === DISPLAY_SETTINGS_FIELDS.BOTTOM_POSITION.toLowerCase().trim(),
                then: Yup.number()
                  .test('maxDigitsAfterDecimal', 'Must be only integer', number => Number.isInteger(number))
                  .max(900719925474099, 'Must be only max integer number')
                  .required('Required'),
              })
              .required('Required'),
          })
          .required('required')
      )

      .test('oneItemNeeded', 'Select a Display Version', (fieldValues: IDisplaySettingFields[] | undefined | null) => {
        let isValid = false;

        if (fieldValues?.length === 0) {
          isValid = true;
        } else {
          fieldValues?.forEach(displaySettings => {
            if (displaySettings.inputValue !== undefined) {
              isValid = true;
            }
          });
        }
        return isValid;
      })
      .defined(),
    displaySettingVersion: Yup.object()
      .shape({
        name: Yup.string().required('required'),
        value: Yup.string().nullable().required('Required'),
        inputType: Yup.string().nullable().required('Required'),
      })

      .test('oneItemNeeded', 'Select a Display Version1', fieldValue => {
        let isValid = false;
        if (fieldValue?.value !== null) {
          isValid = true;
        }

        return isValid;
      }),

    connectedRadarSettings: Yup.array()
      .of(
        Yup.object()
          .shape({
            value: Yup.string()
              .nullable(true)
              .required('Required')
              .when('name', {
                is: name => name && name.includes(CONNECTED_RADAR_SETTINGS_V2.ARM_LENGTH),
                then: Yup.string()
                  .matches(/\.\d+$/, {
                    message: 'Must be a decimal value',
                  })
                  .required('Required'),
              })
              .when('name', {
                is: name => name && name.includes(CONNECTED_RADAR_SETTINGS_V2.COEFFICIENT_0),
                then: Yup.string()
                  .matches(/\.\d+$/, {
                    message: 'Must be a decimal value',
                  })
                  .required('Required'),
              })
              .when('name', {
                is: name => name && name.includes(CONNECTED_RADAR_SETTINGS_V2.COEFFICIENT_1),
                then: Yup.string()
                  .matches(/\.\d+$/, {
                    message: 'Must be a decimal value',
                  })
                  .required('Required'),
              })
              .when('name', {
                is: name => name && name.includes(CONNECTED_RADAR_SETTINGS_V2.LOWER_FREQUENCY),
                then: Yup.string()
                  .matches(/\.\d+$/, {
                    message: 'Must be a decimal value',
                  })
                  .required('Required'),
              })
              .when('name', {
                is: name => name && name.includes(CONNECTED_RADAR_SETTINGS_V2.UPPER_FREQUENCY),
                then: Yup.string()
                  .matches(/\.\d+$/, {
                    message: 'Must be a decimal value',
                  })
                  .required('Required'),
              })
              .when('name', {
                is: name => name && name.includes(CONNECTED_RADAR_SETTINGS_V2.SCALE_FACTOR),
                then: Yup.string()
                  .matches(/\.\d+$/, {
                    message: 'Must be a decimal value',
                  })
                  .required('Required'),
              }),
            inputValue: Yup.number()
              .nullable(true)
              .when('name', {
                is: name => name && name.includes(CONNECTED_RADAR_SETTINGS_V1.FILE_SIZE),
                then: Yup.number()
                  .min(625, 'Range must be between 625 - 3296')
                  .max(3296, 'Range must be between 625 - 3296')
                  .required('Required'),
              })
              .when('name', {
                is: name => name && name.includes(CONNECTED_RADAR_SETTINGS_V1.PASSKEY_TIMEOUT),
                then: Yup.number()
                  .min(1, 'Range must be between 1 - 525600')
                  .max(525600, 'Range between 1 - 525600')
                  .required('Required'),
              })
              .when('name', {
                is: name => name && name.includes(CONNECTED_RADAR_SETTINGS_V2.WEATHER_DATA),
                then: Yup.number()
                  .min(30, 'Range must be between 30 - 300')
                  .max(300, 'Range must be between 30 - 300')
                  .required('Required'),
              })
              .when('name', {
                is: name => name && name.includes(CONNECTED_RADAR_SETTINGS_V2.MAINTANANCE_DATA),
                then: Yup.number()
                  .min(600, 'Range must be between 600 - 3600')
                  .max(3600, 'Range must be between 600 - 3600')
                  .required('Required'),
              })
              .when('name', {
                is: name => name && name.includes(CONNECTED_RADAR_SETTINGS_V1.LABEL_RATE_1),
                then: Yup.number()
                  .min(1, 'Range must be between 1 - 24')
                  .max(24, 'Range must be between 1 - 24')
                  .required('Required'),
              })
              .when('name', {
                is: name => name && name.includes(CONNECTED_RADAR_SETTINGS_V1.LABEL_RATE_2),
                then: Yup.number()
                  .min(1, 'Range must be between 1 - 48')
                  .max(48, 'Range must be between 1 - 48')
                  .required('Required'),
              })
              .when('name', {
                is: name => name && name.includes(CONNECTED_RADAR_SETTINGS_V2.ARINC619_MESSAGE_INTERVAL),
                then: Yup.number()
                  .min(0, 'Range must be between 0 - 1000')
                  .max(1000, 'Range must be between 0 - 1000')
                  .required('Required'),
              }),
          })
          .required('Connected Required')
      )
      .defined(),
  })
  .required('Required');
