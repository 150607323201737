export const LABELS = {
  make: 'Make:',
  model: 'Model:',
  stc: 'Cert Number:',
  installMode: 'Install Mode:',
  gateway: 'Wireless Gateway:',
  antennaeDriveSerial: 'Antennae Drive (DA) Serial #',
  tail: 'Tail #',
  acSerial: 'AC Serial #',
};
