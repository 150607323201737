import React, { FC } from 'react';
import { Card } from '@scuf/common';
import { useSelector } from 'react-redux';
import { selectCustomer } from 'stores/CustomerStore';
import { IConfigData } from 'models/configuration';
import { PLACEHOLDER_CONTENT } from '../constants';
import { selectUserRole } from 'stores/AuthStore';

interface IPlaceHolderContentProps {
  configurationData: IConfigData[];
  loading: boolean;
}

export const PlaceHolderContent: FC<IPlaceHolderContentProps> = ({ configurationData, loading }) => {
  const selectedCustomer = useSelector(selectCustomer);
  const userRole = useSelector(selectUserRole);
  let placeHolderText: string;

  if (loading) {
    placeHolderText = '';
  } else if (selectedCustomer === null) {
    placeHolderText = PLACEHOLDER_CONTENT.noAccountSelected;
  } else {
    placeHolderText = PLACEHOLDER_CONTENT.beginConfiguration;
  }

  return (userRole !== 'agent' && userRole !== 'admin' && selectedCustomer === null) ||
    configurationData.length === 0 ? (
    <Card interactive={false} className="config-table-card">
      <Card.Content className="config-table-card-content">{placeHolderText}</Card.Content>
    </Card>
  ) : null;
};
