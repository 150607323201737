export const HEADING_TEXT = {
  heading1: 'Give this configuration a reference name so you can locate it in your configuration file history:',
};

export const INPUT_LABEL = {
  configReferenceName: 'Configuration Reference Name:',
  systemGenerated: 'System Generated File name:',
  configReferenceNameField: 'configFileRefName',
  systemGeneratedField: 'sysGenFileName',
};

export const BUTTON_CONTENT = {
  save: 'SAVE',
};

export const PLACEHOLDER_TEXT = {
  configReferenceName: 'BlueSkies 100 AB N2220',
  systemGenerated: '[file name rules for automated naming].txt',
};
