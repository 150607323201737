export const HEADING_TEXT = {
  header: 'Software Configured!',
  content1:
    'The operator settings and software for your RDR 4000 have been successfully configured. Download the file now for installation.',
  content2: 'The file must be loaded onto the radar via SD Card.',
  content3: 'For future use, this file can be retrieved in your',
  content4: 'Configuration File List',
  content5: '(ASDS > Configure Device > RDR 4000 > Create/Modify configuration).',
  separateEmails: 'Separate emails with commas',
};

export const BUTTON_CONTENT = {
  downloadPreparing: 'DOWNLOAD PREPARING',
  download: 'DOWNLOAD',
};
