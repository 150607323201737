import '@scuf/common/honeywell/theme.css';
import '@scuf/datatable/honeywell/theme.css';
import 'index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from 'stores/GlobalStore';
import { App } from './App';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
